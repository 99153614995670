/** @jsx jsx */
import { jsx, Text, Grid, Box, Badge } from "theme-ui";
import { useEffect } from "react";
import { get, compact } from "sites-common/utils/lodash";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import RenderAbhyasiDetails from "gatsby-plugin-hfn-profile/components/SrcmEntities/Render/RenderAbhyasiDetails";
import SrcmForm from "gatsby-plugin-hfn-profile/components/SrcmForm";
import { DefaultButton } from "office-ui-fabric-react";
import formDefinition from "./formDefinition";

const one = "one";

function AbhyasiRegistrationForm() {
  const { srcmProfile } = useAuth();

  const srcmApiForForm = SrcmForm.useSrcmApiForForm(
    {
      api: "/api/v2/abhyasis/create_aspirant/",
      client: "profileClient",
    },
    null,
    formDefinition,
    one
  );

  const {
    apiData,
    apiDataVersion,
    formData,
    updateFormField,
    setFormFields,
    startOver,
  } = srcmApiForForm;

  const { first_name, middle_name, last_name } = formData;
  const { name } = apiData;

  useEffect(() => {
    const {
      abhyasiId,
      id,
      name: name1,
      city_id,
      city,
      state_id,
      state,
      country_id,
      country,
      srcm_group_id,
      srcm_group,
    } = srcmProfile;
    const defaults = {
      welcome_card_signed_by_ref: abhyasiId,
      first_sitting_by: { id, name: name1 },
      city_id: { id: city_id, name: city },
      state: { id: state_id, name: state },
      country: { id: country_id, name: country },
      srcm_group: { id: srcm_group_id, name: srcm_group },
    };
    setFormFields(defaults);
  }, [srcmProfile, setFormFields]);

  useEffect(() => {
    if (!name) {
      updateFormField(
        compact([first_name, middle_name, last_name]).join(" "),
        "name"
      );
    }
  }, [updateFormField, first_name, last_name, middle_name, name]);

  if (apiDataVersion > 1 && formData?.ref) {
    return (
      <div>
        <Badge variant="info"> Successfully Registered </Badge>

        <Box
          sx={{
            my: 2,
            py: 1,
            textAlign: "center",
            fontFamily: "monospace",
            color: "#666",
            letterSpacing: "10px",
            fontSize: "32px",
            lineHeight: "32px",
            border: "5px #999 dashed",
            background: "#eee",
          }}
        >
          <span>{formData.ref}</span>
        </Box>

        <RenderAbhyasiDetails abhyasiId={formData?.ref} maskPII={false} />

        <DefaultButton
          sx={{ my: 4 }}
          onClick={startOver}
          text="Register Another Abhyasi"
        />
      </div>
    );
  }

  return (
    <div key={formData ? formData.ref : "A"}>
      <SrcmForm srcmApiForForm={srcmApiForForm} callApiMethod="POST">
        <div sx={{ textAlign: "center", my: 2 }}>
          <Text variant="title">Register New Practitioner</Text>
        </div>

        <div sx={{ textAlign: "left", mb: 3 }}>
          <SrcmForm.SubForm
            title="Aspirant Details"
            name={one}
            allwaysOpen
            saveBtnTxt="Submit"
          >
            <div sx={{ bg: "muted", p: 2, mb: 2 }}>
              <Grid gap={2} columns={3}>
                <SrcmForm.InputField name="first_name" section={one} />
                <SrcmForm.InputField name="middle_name" section={one} />
                <SrcmForm.InputField name="last_name" section={one} />
              </Grid>
              <SrcmForm.InputField name="name" section={one} />
            </div>
            <Text variant="sectionTitle">Contact Details</Text>
            &nbsp;
            <Text variant="description">
              one of email or mobile is required *
            </Text>
            <div sx={{ bg: "muted", p: 2, mb: 2 }}>
              <SrcmForm.InputField name="email" section={one} />
              <SrcmForm.InputField name="mobile" section={one} />
            </div>
            <Text variant="sectionTitle">Demographics</Text>
            <div sx={{ bg: "muted", p: 2, mb: 2 }}>
              <SrcmForm.InputField name="city_id" section={one} />
              <SrcmForm.InputField
                name="srcm_group"
                siblingsOf={get(formData, "srcm_group.id", 0) || 0}
                section={one}
              />

              <Grid gap={2} columns={2}>
                <SrcmForm.InputField name="gender" section={one} />
                <SrcmForm.InputField name="age" section={one} />
              </Grid>
            </div>
            <Text variant="sectionTitle">Introduction Details</Text>
            <div sx={{ bg: "muted", p: 2, mb: 2 }}>
              <SrcmForm.InputField
                name="first_sitting_by"
                section={one}
                includeDflt={
                  srcmProfile?.isPrefect
                    ? [
                        {
                          id: srcmProfile?.id,
                          name: srcmProfile?.name,
                          city: srcmProfile?.city,
                        },
                      ]
                    : []
                }
              />
              <SrcmForm.InputField name="date_of_joining" section={one} />
              <SrcmForm.InputField name="id_number" section={one} />
            </div>
            <Text variant="sectionTitle">Photograph</Text>
            <div sx={{ bg: "muted", p: 2, mb: 2 }}>
              <SrcmForm.InputField
                photo_url={null}
                editOnly
                modes={["edit"]}
                name="photo"
                value={formData.photo}
                onChange={updateFormField}
                noForceOpen
              />
            </div>
          </SrcmForm.SubForm>
        </div>
      </SrcmForm>
    </div>
  );
}

export default AbhyasiRegistrationForm;
