/** @jsx jsx */
import { jsx } from "theme-ui";

import DLWLayout from "../../layouts/dlw-mobile";
import AbhyasiRegistrationForm from "../../components/AbhyasiRegistrationForm";

function AbhyasiRegistrationFormPage() {
  return (
    <DLWLayout>
      <AbhyasiRegistrationForm />
    </DLWLayout>
  );
}

export default AbhyasiRegistrationFormPage;
